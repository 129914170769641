<template>
  <el-dialog
    class="properties-add-dialog"
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    title="Add / Remove Properties to Group"
    @open="fetchData"
  >
    <el-transfer
      v-model="propertiesInGroup"
      v-loading="loading"
      filterable
      :data="propertyList"
      :titles="['Properties not in group', 'Properties in group']"
      :props="{
        key: 'id',
        label: 'name'
      }"
    />

    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        type="primary"
        :disabled="!propertiesChanged"
        @click="submit"
      >
        Add / Remove Properties
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import RooofAPI from '@/services/api/rooof'
import { getProperties } from '@/utils/groups'
export default {
  name: 'PropertiesAddRemoveDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      propertyList: [],
      propertiesInGroup: [],
      originalPropertiesInGroup: [],
      loading: false
    }
  },
  computed: {
    propertiesChanged () {
      return !(this.propertiesInGroup.length === this.originalPropertiesInGroup.length &&
      this.propertiesInGroup.every(groupId => this.originalPropertiesInGroup.includes(groupId)))
    }
  },
  methods: {
    /**
     * Fetches all properties that are associated with the company
     * and gets the properties that are already in the group.
     */
    async fetchData () {
      try {
        this.loading = true
        const properties = await RooofAPI.companies.propertySummary(this.$route.params.cid)
        this.propertyList = properties.map(property => { return { id: property.id, name: property.name } })
        this.propertiesInGroup = this.originalPropertiesInGroup = getProperties(this.group).map(property => property.id)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Handler for when the add / remove properties button is clicked.
     * Emits the added and removed properties.
     */
    submit () {
      const properties = {
        added: this.propertiesInGroup.filter(groupId => !this.originalPropertiesInGroup.includes(groupId)),
        removed: this.originalPropertiesInGroup.filter(groupId => !this.propertiesInGroup.includes(groupId))
      }
      this.$emit('submit', properties)
    }
  }
}
</script>

<style lang="scss" scoped>
.properties-add-dialog {
  ::v-deep .el-transfer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-transfer-panel {
      width: 100%;
    }

    .el-transfer__buttons {
      display: flex;
      flex-direction: column-reverse;
      .el-button {
        margin: 5px 0;
      }
    }
  }
}
</style>
