<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    title="New Sub Group"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      @submit.native.prevent
    >
      <el-form-item label="Name" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          v-model="form.description"
          type="textarea"
          :rows="3"
        />
      </el-form-item>
      <el-form-item
        label="Parent Group"
        prop="parent"
        class="is-required"
      >
        <el-select v-model="form.parent" value-key="id">
          <el-option
            v-for="option in groupOptions"
            :key="option.id"
            :label="option.name"
            :value="option"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button
        type="primary"
        @click="validateAndSubmit"
      >
        Create
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: 'SubgroupAdd',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    group: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: {
        name: '',
        description: '',
        parent: {}
      },
      rules: {
        name: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 128, message: 'Name cannot be more than 128 characters', trigger: 'blur' }
        ],
        parent: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    groupOptions () {
      return this.getGroupOptions(this.group)
    }
  },
  watch: {
    parent (value) {
      this.form.parent = value
    }
  },
  methods: {
    /**
     * Validates form data and sends data to parent.
     */
    validateAndSubmit () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          data.parent = this.form.parent.id // override with pkey
          this.$emit('submit', data)
          this.reset()
        }
      })
    },
    /**
     * Emits close event and resets form fields.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Resets form fields and validation.
     */
    reset () {
      this.$refs['form'].resetFields()
      this.$refs['form'].clearValidate()
    },
    /**
     * Returns all available groups in the nested structure.
     *
     * @param {Object} group - group structure
     */
    getGroupOptions (group) {
      const groups = [{ id: group.id, name: group.name }]
      for (const subgroup of group.children) {
        groups.push(...this.getGroupOptions(subgroup))
      }
      return groups
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
