<template>
  <drawer
    :visible="visible"
    :show-close="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <template #title>
      <div class="drawer-title">
        <h1>
          {{ property.name }}
        </h1>
        <el-button
          v-if="!group.contains_all_properties"
          class="property-delete-btn"
          type="text"
          icon="el-icon-delete"
          @click="removeProperty"
        />
      </div>
      <p class="el-drawer__subtitle">
        Property
      </p>
    </template>
    <el-form
      v-loading="loading"
      :model="form"
      label-position="top"
      @submit.native.prevent
    >
      <h1>Property Details</h1>
      <div v-if="propertyAddress" class="property-address">
        <label>Address</label>
        <p>
          {{ propertyAddress }}
        </p>
      </div>
      <el-form-item
        label="Parent Group"
        prop="parent"
        class="is-required"
      >
        <el-select v-model="form.parent" value-key="id">
          <el-option
            v-for="option in parentOptions"
            :key="option.id"
            :label="option.name"
            :value="option"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :disabled="!formChanged"
          @click="submit"
        >
          Save
        </el-button>
        <el-button :disabled="!formChanged" @click="getParentOptions(group, propertyId)">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </drawer>
</template>

<script>
import Drawer from '@/components/drawers/Drawer'
import RooofAPI from '@/services/api/rooof'
import { isValidAddress, formatAddress } from '@/utils/rooof'
export default {
  name: 'PropertyEdit',
  components: {
    'drawer': Drawer
  },
  props: {
    group: {
      required: true,
      type: Object
    },
    propertyId: {
      type: Number,
      default: null
    },
    visible: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      property: {},
      form: {
        parent: undefined
      },
      originalForm: {}
    }
  },
  computed: {
    propertyAddress () {
      if (this.property &&
          this.property.property_address &&
          isValidAddress(this.property.property_address)) {
        return formatAddress(this.property.property_address)
      }
      return ''
    },
    parentOptions () {
      if (!this.propertyId) {
        return []
      }
      return this.getParentOptions(this.group, this.propertyId)
    },
    formChanged () {
      return JSON.stringify(this.originalForm) !== JSON.stringify(this.form)
    }
  },
  watch: {
    propertyId: {
      immediate: true,
      handler () {
        if (this.propertyId) {
          this.getProperty(this.propertyId)
        }
      }
    }
  },
  methods: {
    /**
     * Fetch the property from the API.
     * @param {Number} id - property id
     */
    async getProperty (id) {
      try {
        this.loading = true
        this.property = await RooofAPI.properties.retrieve(id)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Recursive function that returns all groups in the structure and
     * sets currently selected parent given the property id.
     *
     * @param {Object} group - group structure to traverse
     * @param {Number} propertyId - id of the property being edited
     */
    getParentOptions (group, propertyId) {
      const options = [group]
      if (group.properties.some(property => property.id === propertyId)) {
        this.form.parent = this.originalForm.parent = group
      }
      for (const subgroup of group.children) {
        options.push(...this.getParentOptions(subgroup, propertyId))
      }
      return options
    },
    /**
     * Handler for save button.
     */
    submit () {
      // to change the property's parent, we need to add to the new group then remove the property from old group
      // the order is important since some groups contains all properties
      const requests = [
        { id: this.form.parent.id, properties: [...this.form.parent.properties, { id: this.propertyId }] },
        { id: this.originalForm.parent.id, properties: this.originalForm.parent.properties.filter(property => property.id !== this.propertyId) }
      ]
      this.$emit('property-edit', requests)
    },
    /**
     * Handler for delete button.
     */
    removeProperty () {
      const request = { id: this.form.parent.id, properties: this.form.parent.properties.filter(property => property.id !== this.propertyId) }
      this.$emit('property-remove', request)
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-title {
  display: flex;

  h1 {
    margin: 0;
  }
  .property-delete-btn {
    padding: 0;
    margin-left: 0.5em;
    font-size: 18px;
  }
}
.el-drawer__subtitle {
  text-transform: capitalize;
}
.property-address {
  margin: 2em 0;
  label {
    font-size: 14px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    margin: 0.5em 0;
  }
}
.el-select {
  width: 100%;
}
</style>
