<template>
  <el-collapse :value="activeNames" @input="collapseHandler">
    <draggable
      :list="groups"
      group="groups"
      :sort="false"
      ghost-class="ghost"
      :disabled="topLevel"
      @change="groupChangeHandler($event, parent)"
    >
      <el-collapse-item
        v-for="group in groups"
        :key="group.id"
        :name="group.id"
      >
        <template slot="title">
          <span class="collapse-label" @click.stop="$emit('group-edit', group)">
            <i class="el-icon-folder" />
            {{ group.name }}
          </span>
          <span v-if="!topLevel" class="action-buttons">
            <el-button
              type="text"
              icon="el-icon-folder-add"
              @click.stop="$emit('group-add', group)"
            />
            <el-button
              type="text"
              icon="el-icon-close"
              @click.stop="$emit('delete', group)"
            />
          </span>
        </template>

        <draggable
          :list="group.properties"
          group="properties"
          tag="ul"
          :sort="false"
          ghost-class="ghost"
          class="property-list"
          @change="propertyChangeHandler($event, group.id, group.properties)"
        >
          <li
            v-for="property in group.properties"
            :key="property.id"
            @click="$emit('property-edit', property)"
          >
            {{ property.name }}
          </li>
        </draggable>

        <group-structure-list
          :class="{ 'collapse-no-border' : !group.children.length }"
          :groups="group.children"
          :parent="group.id"
          @delete="$emit('delete', $event)"
          @group-change="$emit('group-change', $event)"
          @property-change="$emit('property-change', $event)"
          @group-edit="$emit('group-edit', $event)"
          @property-edit="$emit('property-edit', $event)"
          @group-add="$emit('group-add', $event)"
        />
      </el-collapse-item>
    </draggable>
  </el-collapse>
</template>

<script>
import Draggable from 'vuedraggable'
export default {
  name: 'GroupStructureList',
  components: {
    'draggable': Draggable
  },
  props: {
    groups: {
      required: true,
      type: Array
    },
    topLevel: {
      type: Boolean,
      default: false
    },
    parent: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      activeNames: this.getGroupIds(this.groups)
    }
  },
  methods: {
    /**
     * @param {Array} groups - Array of groups
     * @returns {Array} Array of group ids
     */
    getGroupIds (groups) {
      return groups.map(group => group.id)
    },
    /**
     * Handler for when a group is collapsed/expanded
     *
     * @param {Array} val - Array of group ids that are expanded
     */
    collapseHandler (val) {
      this.activeNames = val
    },
    /**
     * Handler for when a group has been moved to another group.
     *
     * @param {Object} event - change event
     * @param {Number} parent - the id of the group's new parent
     */
    groupChangeHandler (event, parent) {
      event.parent = parent
      this.$emit('group-change', event)
    },
    /**
     * Handler for when a property has been moved to another group.
     *
     * @param {Object} event - change event
     * @param {Number} groupId - id of group the property moved to
     * @param {Array} properties - properties of the new group including this one
     */
    propertyChangeHandler (event, groupId, properties) {
      event.groupId = groupId
      event.properties = properties
      this.$emit('property-change', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-collapse::v-deep {
  padding-top: 1px; // need this to nest subgroups without properties
  &:not(:first-child) {
    margin-left: 2em;
  }
  &:last-child {
    border-bottom: none;
  }
  .collapse-no-border {
    border: none;
  }
  .el-collapse-item {
    .el-collapse-item__header {
      padding-left: 3em;
      padding-right: 1em;
      display: flex;
      justify-content: space-between;
      position: relative;

      &:hover {
        background-color: rgba(#000, 0.03);
      }

      .el-collapse-item__arrow {
        position: absolute;
        left: 1em;
      }

      .action-buttons {
        display: flex;
        i {
          font-size: 18px;
        }
      }

      .collapse-label {
        i {
          font-size: 16px;
          margin-right: 0.5em;
        }
        width: 100%;
        font-weight: 700;
      }
    }

    .el-collapse-item__content {
      padding-bottom: 0;
    }

    .property-list {
      margin: 0;
      list-style-type: none;

      li {
        height: 48px;
        padding: 0 2em;
        align-items: center;
        display: flex;
        border-top: 1px solid #ebeef5;
        cursor: pointer;

        &:hover {
          background-color: rgba(#000, 0.03);
        }
      }
    }
  }
  .ghost, .ghost > .el-collapse-item__header {
    opacity: 0.8;
    background-color: rgba(#4fa7ff, 0.1);
  }
}
</style>
