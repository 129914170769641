<template>
  <div v-if="group">
    <el-row class="group-header">
      <el-button
        type="text"
        icon="el-icon-back"
        @click="$router.push({ name: 'ReportingGroups' })"
      />
      <div>
        <h1 class="group-header__title">
          {{ group.name }}
        </h1>
        <p class="group-header__subtitle">
          {{ group.description }}
        </p>
      </div>
    </el-row>
    <el-row type="flex" justify="end">
      <el-button
        v-if="!group.contains_all_properties"
        type="success"
        size="medium"
        :disabled="loading"
        @click="showPropertiesAddRemoveDialog = true"
      >
        Add / Remove Properties
      </el-button>
      <el-button
        icon="el-icon-folder-add"
        type="primary"
        size="medium"
        :disabled="loading"
        @click="addSubGroupHandler"
      >
        Add Sub Group
      </el-button>
    </el-row>
    <el-row v-loading="loading" class="group-structure-container">
      <group-structure-list
        :groups="[group]"
        :parent="group.id"
        top-level
        @delete="deleteHandler"
        @group-change="groupChangeHandler"
        @property-change="propertyChangeHandler"
        @group-edit="editGroup"
        @property-edit="editProperty"
        @group-add="addSubGroupHandler"
      />
    </el-row>
    <group-delete-dialog
      :show="showGroupDeleteDialog"
      :group="groupToDelete"
      @close="showGroupDeleteDialog = false"
      @delete="confirmDeleteHandler"
    />
    <subgroup-add-dialog
      :show="showSubgroupAddDialog"
      :group="group"
      :parent="addSubGroupParent"
      @close="showSubgroupAddDialog = false"
      @submit="createSubgroup"
    />
    <properties-add-remove-dialog
      :show="showPropertiesAddRemoveDialog"
      :group="group"
      @close="showPropertiesAddRemoveDialog = false"
      @submit="addRemoveProperties"
    />
    <group-edit
      :group="groupToEdit"
      :group-structure="group"
      :type="enums.groupTypes.REPORTING"
      :visible.sync="showEditGroupDrawer"
      @delete="deleteHandler"
      @refresh="refreshEdit"
    />
    <property-edit
      :group="group"
      :property-id="propertyToEdit"
      :visible.sync="showEditPropertyDrawer"
      @property-edit="propertyEditHandler"
      @property-remove="propertyRemoveHandler"
    />
  </div>
  <div v-else-if="!loading">
    Sorry, that group doesn't seem to exist.
  </div>
</template>

<script>
import CraigslistAPI from '@/services/api/craigslist'
import GroupEdit from './_components/GroupEdit/GroupEdit'
import GroupStructureList from './_components/GroupStructureList'
import GroupDeleteDialog from './_components/GroupDeleteDialog'
import SubgroupAddDialog from './_components/SubgroupAddDialog'
import PropertiesAddRemoveDialog from './_components/PropertiesAddRemoveDialog'
import PropertyEdit from './_components/PropertyEdit'
import { enums } from '@/utils/constants'

export default {
  name: 'GroupStructure',
  beforeRouteUpdate (to, from, next) {
    if (to.params.gid !== from.params.gid) {
      this.getGroup()
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('CLEAR', 'selectedGroup')
    next()
  },
  components: {
    'group-edit': GroupEdit,
    'group-structure-list': GroupStructureList,
    'group-delete-dialog': GroupDeleteDialog,
    'subgroup-add-dialog': SubgroupAddDialog,
    'properties-add-remove-dialog': PropertiesAddRemoveDialog,
    'property-edit': PropertyEdit
  },
  data () {
    return {
      group: undefined,
      loading: false,
      showSubgroupAddDialog: false,
      showGroupDeleteDialog: false,
      showPropertiesAddRemoveDialog: false,
      showEditGroupDrawer: false,
      showEditPropertyDrawer: false,
      addSubGroupParent: {},
      groupToDelete: {},
      groupToEdit: {},
      propertyToEdit: null,
      propertyMoved: {}
    }
  },
  created () {
    this.enums = enums
    this.getGroup()
  },
  methods: {
    /**
     * Fetch the Group object from the API.
     */
    async getGroup () {
      try {
        this.loading = true
        const response = await CraigslistAPI.groups.retrieve(this.$route.params.gid)
        this.group = JSON.parse(JSON.stringify(response))
        this.$store.commit('SET', ['selectedGroup', response])
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Calls API to create a Group.
     */
    async createSubgroup (group) {
      group.company = this.group.company
      group.properties = []
      try {
        this.showSubgroupAddDialog = false
        this.loading = true
        await CraigslistAPI.groups.create(group)
        this.$message({
          message: `Sub Group ${group.name} created.`,
          type: 'success',
          duration: 3500
        })
        this.getGroup()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Calls API to update a Group.
     */
    async updateGroup (groupId, data) {
      try {
        this.loading = true
        await CraigslistAPI.groups.partialUpdate(groupId, data)
        this.getGroup()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Calls API to delete a Group.
     *
     * @param {Object} group
     */
    async deleteGroup (group, params) {
      this.loading = true
      try {
        await CraigslistAPI.groups.delete(group.id, params)
        this.$message({
          message: `Sub Group ${group.name} deleted.`,
          type: 'success',
          duration: 3500
        })
        // redirect to group list if deleting the structure
        if (group.id === this.group.id) {
          this.$router.push({ name: 'ReportingGroups', params: { cid: this.$route.params.cid } })
        } else {
          this.getGroup()
        }
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.showEditGroupDrawer = false
      }
    },
    /**
     * Handler for when the add subgroup button is clicked.
     */
    addSubGroupHandler (group) {
      this.addSubGroupParent = { id: group.id || this.group.id, name: group.name || this.group.name }
      this.showSubgroupAddDialog = true
    },
    /**
     * Shows a confirmation dialog if it is a top level group,
     * or if the subgroup has any children or properties.
     *
     * @param {Object} group
     */
    deleteHandler (group) {
      if (group.children.length || group.properties.length || !group.parent.name) {
        this.groupToDelete = group
        this.showGroupDeleteDialog = true
      } else {
        this.deleteGroup(group)
      }
    },
    /**
     * Handler for when the delete button from the confirmation dialog is clicked.
     */
    confirmDeleteHandler () {
      this.showGroupDeleteDialog = false
      // if it is a top-level group, we want to delete all children
      const params = this.groupToDelete.parent.name ? { confirm_move_children: true } : { force_delete_children: true }
      this.deleteGroup(this.groupToDelete, params)
    },
    /**
     * Handler for when a subgroup has been drag and dropped.
     */
    groupChangeHandler (event) {
      if (event.added) {
        const group = event.added.element
        this.updateGroup(group.id, { parent: event.parent, properties: group.properties })
      }
    },
    /**
     * Handler for when a property has been drag and dropped.
     * Properties should be added before removed to ensure groups that contain all properties
     * keep its integrity.
     */
    async propertyChangeHandler (event) {
      this.propertyMoved[event.added ? 'added' : 'removed'] = {
        groupId: event.groupId,
        properties: event.properties
      }

      if (this.propertyMoved.added && this.propertyMoved.removed) {
        try {
          this.loading = true
          await CraigslistAPI.groups.partialUpdate(this.propertyMoved.added.groupId,
            { properties: this.propertyMoved.added.properties })
          await CraigslistAPI.groups.partialUpdate(this.propertyMoved.removed.groupId,
            { properties: this.propertyMoved.removed.properties })
          this.propertyMoved = {}
          this.getGroup()
        } catch (err) {
          this.loading = false
          const details = err.response ? err.response.data : null
          this.$rfAlert.error(this, err.toString(), details)
        }
      }
    },
    /**
     * Handler for when Add / Remove properties dialog is submitted
     * @param {Object} properties - properties added and removed.
     */
    async addRemoveProperties (properties) {
      this.loading = true
      this.showPropertiesAddRemoveDialog = false
      try {
        if (properties.added.length) {
          const propertiesAdded = properties.added.map(groupId => { return { id: groupId } })
          const groupProperties = [...this.group.properties, ...propertiesAdded]
          this.group = await CraigslistAPI.groups.partialUpdate(this.group.id, { properties: groupProperties })
        }
        if (properties.removed.length) {
          const groupsToRemoveFrom = this.removeProperties(this.group, properties.removed)
          const promises = []
          for (const group of groupsToRemoveFrom) {
            promises.push(CraigslistAPI.groups.partialUpdate(group.id, { properties: group.properties }))
          }
          await Promise.all(promises)
        }
        this.$message({
          message: 'Successfully added / removed properties.',
          type: 'success',
          duration: 3500
        })
        this.getGroup()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Recursive function that removes the given properties from their subgroups
     * and returns the affected groups and their remaining properties
     *
     * @param {Object} group - structure object to iterate
     * @param {Array} propertiesToRemove - ids of properties to remove
     * @returns {Array} - groups affected with their remaining properties
     */
    removeProperties (group, propertiesToRemove) {
      const groups = []
      if (group.properties.some(property => propertiesToRemove.includes(property.id))) {
        groups.push({
          id: group.id,
          properties: group.properties.filter(property => !propertiesToRemove.includes(property.id))
        })
      }
      for (const subgroup of group.children) {
        groups.push(...this.removeProperties(subgroup, propertiesToRemove))
      }
      return groups
    },
    /**
     * Opens the edit group drawer.
     *
     * @param {Object} group
     */
    editGroup (group) {
      this.groupToEdit = group
      this.groupToEdit.type = this.$route.params.type
      this.showEditGroupDrawer = true
    },
    /**
     * Re-fetches the group and the group being edited.
     */
    async refreshEdit () {
      await this.getGroup()
      this.groupToEdit = await CraigslistAPI.groups.retrieve(this.groupToEdit.id)
      this.groupToEdit.type = this.$route.params.type
    },
    /**
     * Opens the edit property drawer.
     *
     * @param {Object} property
     */
    editProperty (property) {
      this.propertyToEdit = property.id
      this.showEditPropertyDrawer = true
    },
    /**
     * Handler for when a property's parent has been changed in the property edit drawer.
     *
     * @param {Array} requests - groups that need to be updated
     */
    async propertyEditHandler (requests) {
      try {
        this.loading = true
        for (const request of requests) {
          await CraigslistAPI.groups.partialUpdate(request.id, { properties: request.properties })
        }
        this.getGroup()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Handler for when a property has been removed in the property edit drawer.
     *
     * @param {Object} request - group that needs to be updated
     */
    propertyRemoveHandler (request) {
      this.showEditPropertyDrawer = false
      this.updateGroup(request.id, { properties: request.properties })
    }
  }
}
</script>

<style lang="scss" scoped>
.group-header {
  display: flex;
  align-items: flex-start;
  .el-button {
    font-size: 32px;
    margin-right: 0.5em;
    padding: 0;
  }
  .group-header__title {
    margin: 0;
    font-size: 1.5rem;
  }
  .group-header__subtitle {
    margin: 0.5em 0;
    font-size: 0.75rem;
  }
}
.group-structure-container {
  padding: 2em 0;
}
</style>
